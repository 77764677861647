<template>
  <div class="item-top">
    <div class="imt-left">
      <div class="imt-cats" v-if="categoryList.length>0">
        <div style="padding: 10px 20px">
          <el-input v-model="skuName" clearable size="mini" placeholder="输入商品名称" style="width: 188px;"
                    @keyup.enter.native="toSearch"
                    class="filter-item"/>
          <el-input v-model="skuNo" clearable size="mini" placeholder="输入货号" style="width: 188px;margin-left: 5px"
                    @keyup.enter.native="toSearch"
                    class="filter-item"/>
          <el-button class="filter-item" style="margin-left: 5px" size="mini" type="success" icon="el-icon-search"
                     @click="toSearch">搜索
          </el-button>
          <el-button class="filter-item" style="margin-left: 5px" size="mini" type="warning" icon="el-icon-refresh-left"
                     @click="toReset">重置
          </el-button>
        </div>
        <el-tabs v-model="activeName" @tab-click="choiceCat" v-if="categoryList.length>0">
          <el-tab-pane :label="item.catName" :name="index.toString()"
                       v-for="(item,index) in categoryList"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="imt-cats" v-if="!categoryList.length>0">
        <span class="imt-title1">暂无分类</span>
      </div>
      <div class="imt-list">
        <div class="imt-items" v-if="specs.length>0">
          <div @click="addSpec(item)" class="imt-item" v-for="item in specs">
            <span class="imt-name">{{item.skuName}}</span>
            <span class="imt-price" style="color: #B3B1B1;font-size: 5px">No: {{item.skuNo}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imt-right">
      <div class="buy-item">
        <el-table
          :data="datas"
          height="100%"
          highlight-current-row
          ref="singleTableRef"
          style="width: 100%">
          <el-table-column align="center" width="50">
            <template slot-scope="scope">
              <li @click.stop="delItem(scope.$index)" class="el-icon-delete-solid"
                  style="color:red;cursor: pointer;"></li>
            </template>
          </el-table-column>
          <el-table-column align="center" label="名称" property="itemName"/>
          <el-table-column align="center" label="价格"  property="price" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                disabled
                v-model="scope.row.price"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column align="center" label="定金" property="price" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                v-model="scope.row.dj"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
                @input="setPrice"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column align="center" label="尾款" property="price" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                v-model="scope.row.wk"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {getToken} from "../../../../utils/auth";

  export default {
    name: "billing-sku",
    components: {},
    data() {
      return {
        skuNo: '',
        skuName: '',
        activeName: '',
        specName: '',
        orderId: '',
        categoryList: [],
        datas: [],
        dataIndex: -1,
        catIndex: 0,
        catId: '',
        specs: [],
        total: 0,

        memberCard: this.mc,

        title: '',
        visible: false,
        spList: [],
        skuItem: {},

        composes: [],
        dxItemIds: [],
        dxItems: [],
        syVisible: false,
        specNo: '',

      };
    },
    props: {
      mc: Object,
      specData: Array,
      specTotal: Number,
      memberId: String
    },

    async created() {
      this.datas = this.specData;
      this.specTotal = this.specTotal;
      await this.getCategoryList();
      await this.getSkuList();
    },

    methods: {

      //获取项目分类列表
      async getCategoryList() {
        let data = {
          token: getToken(),
          catType: 1,
          specType: 'FW',
        }
        let res = await this.$get("/sw/getSwSkuCategoryList", data);
        this.categoryList = [...res.categoryList];
        if (res.categoryList.length > 0) {
          this.catId = res.categoryList[0].id
        }
      },
      async toSearch() {
        if (!this.skuName && !this.skuNo) {
          this.$message.error("请输入查询条件!")
          return;
        }
        let data = {
          skuName: this.skuName,
          skuNo: this.skuNo,
          isSell: 2,
          token: getToken(),
        }
        let res = await this.$get("/sw/getSwSkuSpecList", data);
        this.specs = res.specs;
      },
      toReset() {
        this.getSkuList()
      },
      //获取规格商品列表
      async getSkuList() {
        if (this.catId) {
          let res = await this.$get("/sw/getSwSkuSpecList", {catId: this.catId, isSell: 2, token: getToken()});
          this.specs = res.specs;
        }
      },

      sousuo() {
        var name = this.specName.replace(/\s+/g, "");
        let specs = []
        this.skuItem.skuSpecs.forEach(item => {
          var newStr = item.spec.replace(/\s+/g, "");
          let b = newStr.toUpperCase().match(name.toUpperCase())
          if (b) {
            specs.push(item)
          }
        })
        if (specs.length > 0) {
          this.spList = specs
        } else {
          this.spList = []
        }
      },

      closeEvent() {
        this.visible = false
        this.specNo = ''
      },

      addSpec(sku) {
        if (sku.skuSpecs.length <= 0) {
          this.$message.error("成品没有任何规格参数!")
        }
        var specItem = sku.skuSpecs[0]
        // if (!this.specNo){
        //   this.$message.error("请填写编码!")
        //   return;
        // }
        let item = {
          itemId: specItem.id,
          categoryId: sku.categoryId,
          itemName: sku.skuName,
          cmnVal: sku.cmnVal,
          fdCmnVal: sku.fdCmnVal,
          cost: specItem.cost,
          sdAch: '',
          price: specItem.retailPrice,
          dj: 0,
          wk: 0,
          oglPrice: specItem.retailPrice,
          spec: specItem.spec,
          itemSign: 0,
          specNo: this.specNo,
        }
        this.datas.push(item)
        this.dataIndex = this.datas.length - 1
        this.$refs.singleTableRef.setCurrentRow(this.datas[this.dataIndex], true);
        this.$emit('setSpecs', this.datas, this.total)
        this.specNo = ''
      },



      //移除已选项目
      delItem(index) {
        let item = this.datas[index];
        this.datas.splice(index, 1);
        var to = parseFloat(this.total) - parseFloat(item.dj);
        this.total = to.toFixed(1)
        this.$emit('setSpecs', this.datas, this.total);
        this.$refs.singleTableRef.setCurrentRow(-1)
      },

      //修改价格
      setPrice() {
        var total = 0
        this.datas.forEach(item => {
          total = parseFloat(total) + parseFloat(item.dj)
        })
        this.total = total.toFixed(1)
        this.$emit('setSpecs', this.datas, this.total);
        this.$refs.singleTableRef.setCurrentRow(-1)
      },

      choiceCat() {
        this.catIndex = this.activeName;
        this.catId = this.categoryList[this.activeName].id;
        this.getSkuList()
      },


      setSkuList(items) {
        this.datas = items
      },
      setMemberCard(item) {
        this.memberCard = item
      },
      //计算价格
      calculationPrice(num1, num2) {
        var num = parseInt(num1 * num2 / 10)
        return num;
      }
    },
  };
</script>


<style lang="scss" scoped>
  .item-top {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .imt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
    overflow: hidden;
  }

  .imt-cats {
    border-bottom: #E6E6E6 solid 1px;
    overflow: hidden;
    padding: 0px 8px;
  }

  .imt-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .imt-title1 {
    display: block;
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

  .imt-list {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .imt-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .imt-item {
    /*flex: 1;*/
    box-sizing: border-box;
    border: #DDDDDD solid 1px;
    width: 138px;
    height: 60px;
    padding: 4px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    /*width: calc((100% - 60px) / 5); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 5);*/
    /*max-width: calc((100% - 60px) / 5);*/

    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .imt-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imt-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 13px;
    text-align: center;
  }

  .imt-right {
    width: 41%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .buy-item {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: column;
  }

  .imt-emp {
    height: 35%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .imt-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .emp-st {
    cursor: pointer;
  }

  .gw-emp .name {
    width: 90%;
    margin-left: 5px;
  }

  .gw-emp .hui {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }

  .cg-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .cg-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 100%;
    cursor: pointer;
    padding-left: 8px;
  }

  .gw-emp .hui {
    width: 100%;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .sps {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
  }

  .spsitem {
    display: flex;
    flex-direction: row;
    border-bottom: gainsboro solid 1px;
    padding: 5px 0px;
    justify-content: center;
    align-items: center;
  }

  .spsitem .l {
    width: 40%;
    display: flex;
    flex-direction: column;
  }

  .spsitem .z {
    width: 40%;
  }

  .spsitem .r {
    width: 20%;
  }

  .spsitem .r span {
    color: #FEA587;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
  }

</style>
