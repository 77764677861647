<template>
  <div class="item-top">
    <div class="imt-left">
      <div class="imt-cats" v-if="categoryList.length>0">
      <el-tabs v-model="activeName" @tab-click="choiceCat">
        <el-tab-pane :label="item.catName" :name="index.toString()" v-for="(item,index) in categoryList"></el-tab-pane>
      </el-tabs>
      </div>
      <div class="imt-cats" v-if="!categoryList.length>0">
        <span class="imt-title1">暂无分类</span>
      </div>
      <div class="imt-list">
        <div class="imt-items" v-if="skus.length>0">
          <div @click="addSku(item)" class="imt-item" v-for="item in skus">
            <span class="imt-name">{{item.skuName}}</span>
            <span class="imt-price">¥ {{item.retailPrice}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imt-right">
      <div class="buy-item">
        <el-table
          :data="datas"
          height="100%"
          highlight-current-row
          ref="singleTableRef"
          style="width: 100%">
          <el-table-column align="center" width="50">
            <template slot-scope="scope">
              <li @click.stop="delItem(scope.$index)" class="el-icon-delete-solid"
                  style="color:red;cursor: pointer;"></li>
            </template>
          </el-table-column>
          <el-table-column align="center" label="名称" property="itemName" />
          <el-table-column align="center" label="价格"  property="price" width="100">
          <template slot-scope="scope">
            <el-input-number
              style="width: 90px"
              size="mini"
              disabled
              v-model="scope.row.price"
              auto-complete="off"
              :precision="1"
              :controls="false"
              maxlength="6"
            ></el-input-number>
          </template>
        </el-table-column>
          <el-table-column align="center" label="定金" property="price" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                v-model="scope.row.dj"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
                v-on:input="calculation()"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column align="center" label="尾款" property="price" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                v-model="scope.row.wk"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {getToken} from "../../../../utils/auth";

  export default {
    name: "billing-sku",
    components: {},
    data() {
      return {
        activeName:'',
        orderId: '',
        categoryList: [],
        datas: [],
        dataIndex: -1,
        catIndex: 0,
        catId: '',
        skus: [],
        total: 0,

        memberCard: this.mc,

      };
    },
    props: {
      mc: Object,
      skuData: Array,
      skuTotal: Number
    },

    async created() {
      this.datas = this.skuData;
      this.total = this.skuTotal;
      await this.getCategoryList();
      await this.getSkuList();
    },

    methods: {
      //获取项目分类列表
      async getCategoryList() {
        let data = {
          token: getToken(),
          catType: 1,
          specType:'CG',
        }
        let res = await this.$get("/sw/getSwSkuCategoryList", data);
        this.categoryList = res.categoryList;
        if (res.categoryList.length > 0) {
          this.catId = res.categoryList[0].id
        }
      },

      //获取项目列表
      async getSkuList() {
        if (this.catId) {
          let res = await this.$get("/sw/getSwSkuList", {catId: this.catId,isSell:0,token: getToken()});
          this.skus = res.skus;
        }
      },

      //添加卖品
      addSku(item) {
        var price = item.retailPrice;
        //计算会员价
        if (this.memberCard.id && this.memberCard.skuDiscount != 0) {
          price = this.calculationPrice(item.retailPrice, this.memberCard.skuDiscount)
        }
        this.datas.push({
          itemId: item.id,
          categoryId: item.categoryId,
          itemName: item.skuName,
          cmnVal: item.cmnVal,
          cost: item.cost,
          price: price,
          dj: 0,
          wk: 0,
          oglPrice: item.retailPrice,
          oglCostPrice: item.costPrice,
          fdCmnVal: item.fdCmnVal,
          itemSign: 0,
        });
        this.dataIndex = this.datas.length - 1
        this.$refs.singleTableRef.setCurrentRow(this.datas[this.dataIndex], true);
        this.$emit('setSkus', this.datas, this.total)
      },


      //移除已选项目
      delItem(index) {
        let item = this.datas[index];
        this.datas.splice(index, 1);
        var to = parseFloat(this.total) - parseFloat(item.dj);
        this.total = to.toFixed(1)
        this.$emit('setSkus', this.datas, this.total);
        this.getEmpList();
        this.$refs.singleTableRef.setCurrentRow(-1)
      },

      choiceCat() {
        this.catIndex = this.activeName;
        this.catId = this.categoryList[this.activeName].id;
        this.getSkuList()
      },

      //计算总金额
      calculation(total) {
        var price = 0
        this.datas.forEach(item => {
          if (item.dj) {
          var to = parseFloat(price) + parseFloat(item.dj)
            price = to.toFixed(1)
          }
        })
        this.total = price
        this.$emit('setSkus', this.datas, this.total);
      },


      setSkuList(items) {
        this.datas = items
      },
      setMemberCard(item) {
        this.memberCard = item
      },

      //计算价格
      calculationPrice(num1, num2) {
        var num = parseInt(num1 * num2 / 10)
        return num;
      }
    },
  };
</script>


<style lang="scss" scoped>
  .item-top {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .imt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
    overflow: hidden;
  }

  .imt-cats {
    border-bottom: #E6E6E6 solid 1px;
    overflow: hidden;
    padding: 0px 8px;
  }

  .imt-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .imt-title1 {
    display: block;
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

  .imt-list {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .imt-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .imt-item {
    /*flex: 1;*/
    box-sizing: border-box;
    border: #DDDDDD solid 1px;
    width: 138px;
    height: 60px;
    padding: 4px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    /*width: calc((100% - 60px) / 5); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 5);*/
    /*max-width: calc((100% - 60px) / 5);*/

    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .imt-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imt-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 13px;
    text-align: center;
  }

  .imt-right {
    width: 41%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .buy-item {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: column;
  }

  .imt-emp {
    height: 35%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .imt-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .emp-st {
    cursor: pointer;
  }

  .gw-emp .name {
    width: 90%;
    margin-left: 5px;
  }

  .gw-emp .hui {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }

  .cg-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .cg-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 100%;
    cursor: pointer;
    padding-left: 8px;
  }

  .gw-emp .hui {
    width: 100%;
    cursor: pointer;
    background-color: #DDDDDD;
  }


</style>
